@import '../../../assets/sass/custom';

.cmncrdV1Doctor {
  .swiper {
    padding: 1rem 3.8rem 1rem 0;

    .crdV1DoctorBoxIn {
      cursor: grab;
    }
  }

  .crdV1DoctorBoxIn {
    .crdV1DoctorPic {
      width: 5.2rem;
      height: 5.2rem; flex-shrink: 0;
    }

    // .crdV1DoctorContent {
    //   width: calc(100% - 5.2rem);
    // }
  }
}

@media screen and (max-width: 1199px) {
  .cmncrdV1Doctor {
    .swiper {
      padding-right: 3.5rem;
    }
  }
}

@media screen and (max-width: 991px) {
  // .cmncrdV1Doctor .swiper {
  //   padding-right: 3.5rem;
  // }
  .crdV1DoctorBoxIn .crdV1DoctorPic {
    height: 4.4rem;
    width: 4.4rem;
  }
}
