@import '../../sass/custom';

// a,
// button,
// input,
// ::after,
// ::before {
//     @include cmnTransition(.2s);
// }

// i::before {
//     transition: none;
// }

// Main Body Animation
.desktopBodyIn {
  padding: 3rem;
  
  @include media-breakpoint-down(lg) {
    padding: 1rem;
  }
}

.desktopBody {
  padding: 6.8rem 0 0 8rem;
  // padding: 11.8rem 0 0 8rem;
  position: relative;
  @include cmnTransition($time: 0.25s);

  .desktopBodyIn {
    padding: 3rem 2rem;
    min-height: calc(100vh - 17rem);

    position: relative;
    @include cmnTransition($time: 0.25s);
    background-color: #fff;
  }
}
.desktopBodyDashboard {
  padding: 11.8rem 0 0 8rem !important;
}

.menuShow {
  .desktopBody {
    padding-left: 21.5rem;
  }
}

.mblscreen {
  padding: 9.6rem 0 7rem 0;
  // padding: 6.6rem 0 7rem 0;
  min-height: calc(100vh - 5rem);

  .desktopBodyIn {
    padding: 1.5rem 0; 
  }
}

.mblscreenNotHome {
  padding: 5.6rem 0 7rem 0;
  // padding: 6.6rem 0 7rem 0;
  min-height: calc(100vh - 5rem);

  .desktopBodyIn {
    padding: 1.5rem 0; 
  }
}

//to prevent modal shaking page
.modal-open {
  overflow-y: auto !important;
  padding: 0px !important;
}
@media only screen and (max-width: 600px) {
  .cmnVideoBoxContainer {
    .cmnVideosponser {
      width: 10rem !important;
    }
  }
}

.dskScreen {
  min-height: calc(100vh + 1px);
}

.appTourBody {
  overflow: hidden !important;
  touch-action: none !important;
  -ms-touch-action: none !important;
}

// shine effect

.cmnMasonry {
  position: relative;
  margin: -3rem 0 0 -3rem !important;
  display: flex;
  flex-wrap: wrap;

  // height: auto;
  .cmnMasonryCard {
    padding: 3rem 0 0 3rem !important;
  }
}

//------------------------------- video box
.cmnVideoBoxContainer {
  .cmnVideosponser {
    position: absolute;
    right: 1.2rem;
    top: 1.2rem;
    // border-radius: $radius6;
    width: 16rem;
    margin: 0;
    text-align: center;
    padding: 0.4rem 1rem;
    background-color: $white;
    z-index: 4;

    .swiper-slide,
    .cmnVideosponserIn {
      background-color: $white;
      position: relative;
      height: 4.4rem;
      width: 100%;

      img {
        // @include imgAutoSize;
        position: absolute;
        @include translateX;
      }
    }
  }

  .cmnVideosDbV2Video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.18);

    i {
      @include translateXY;
      position: absolute;
      @include cmnTransition;
    }

    .spinner-border {
      width: 1rem;
      height: 1rem;
      border-width: 0.3rem;
      border-color: rgba(255, 255, 255, 0.6);
      border-right-color: $white;
      opacity: 0;
      @include cmnTransition;
    }

    &.dbClLoading {
      i {
        transform: translate(-50%, -50%) scale(0.8) !important;
      }

      .spinner-border {
        width: 7.2rem;
        height: 7.2rem;
        opacity: 1;
      }
    }

    &.dbClAfterLoading {
      opacity: 0;
    }
  }

  .cmnVideosTrigr {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 91;
  }

  .cmnVideoTime {
    position: absolute;
    z-index: 3;
    left: 1rem;
    bottom: 1rem;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0.3rem 0.8rem;
  }

  .cmnVideoKeep {
    position: absolute;
    z-index: 3;
    right: 1rem;
    bottom: 1rem;
    background-color: rgba(57, 57, 57, 0.8);
    padding: 0.3rem 0.8rem;
  }

  .progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    height: 0.4rem;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0;

    .progress-bar {
      background-color: var(--bs-secondary);
    }
  }
}

// Get Recording Modal input css
.cmnCheckbox .form-check-label {
  color: #080808;
  font-weight: 500;
  min-height: 2rem;
  padding-left: 2.8rem;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.cmnCheckbox .form-check-input {
  cursor: pointer;
  height: 100% !important;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  width: 100% !important;
  z-index: 2;
}

.cmnCheckbox .form-check-label::after,
.cmnCheckbox .form-check-label::before {
  content: '';
  height: 1.8rem;
  left: 0;
  position: absolute;
  top: 0.1rem;
  width: 1.8rem;
  transition: all 0.5s ease-in-out;
}

.cmnCheckbox .form-check-label::before {
  background-color: #fff;
  border: 2px solid #080808;
  border-radius: 0.6rem;
}

.cmnCheckbox .form-check-label::after {
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 1.4rem;
}

.cmnCheckbox .form-check-input:checked + .form-check-label::before {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.cmnCheckbox .form-check-input:checked + .form-check-label {
  color: var(--bs-primary);
}

.oldUser--premium__tag {
  background: linear-gradient(45deg, #cf7000 10%, #ffdd23 52%, #db7600 90%);
  z-index: 12;
}

.countdown::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 35%;
  // right: 0;
  height: 0.5em;
  border-bottom: 1px solid $primary;
  width: 1rem;
}