//card
// .crdV1topPart {
//   max-width: calc(100% - 3.6rem);
// }
.cardV1 {
  @include media-breakpoint-down(sm) {
    // box-shadow: none !important;
    border-color: $body-bg !important;
  }
}

@include media-breakpoint-down(md) {
  .medWikiDetailsV2Main .detailsPageLeft .dtlsPageLeftBody .dtlsTopPart .detailsPageLeftPic {
    width: auto !important;
  }
  .medWikiDetailsV2Main .detailsPageLeft .dtlsPageLeftBody .dtlsTopPart .dtlsTtlArea {
    width: auto !important;
  }
}

//details-page-structure
.alkem--detailsPage {
  .detailsPageLeft {
    // width: calc(100% - 300px);
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  .detailsPageRight {
    // width: 300px;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
.flex-basis {
  flex: 1 1 0;
  @include media-breakpoint-down(sm) {
    flex: 0;
  }
}

// terms and privacy policy

.k_regi_terms_page {
  font-size: 12px;
  color: #080808;
}

.terms_ttl-1 {
  background-color: #355fd3;
  padding: 16px 2%;
  margin-bottom: 30px;
}

.terms_ttl-1 span {
  display: block;
  margin-top: 2px;
}

.terms_ttl-2 {
  margin-bottom: 20px;
  position: relative;
  padding: 0 5% 15px;
}

.terms_ttl-2:before {
  position: absolute;
  bottom: 0px;
  left: 50%;
  width: 60px;
  background-color: #d1d1d1;
  content: '';
  height: 1px;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

.k_regi_terms {
  padding: 30px 0 0 0;
}

.k_regi_terms .my-20 {
  margin: 20px 0;
}

.k_regi_terms ol {
  counter-reset: item;
}

.k_regi_terms li {
  display: block;
}

.k_regi_terms li:before {
  content: counters(item, '.') ' ';
  counter-increment: item;
  margin-right: 8px;
}

.k_regi_terms_ol_1 {
  padding: 0;
}

.k_regi_terms_ol_1 ol {
  padding-left: 20px;
  margin-top: 20px;
}

.k_regi_terms_ol_1 ol li {
  margin-bottom: 15px;
}

.k_regi_terms_ol_1 ol li a {
  text-decoration: underline;
  font-weight: 500;
}

.k_regi_terms_ol_1 ol li:last-child {
  margin-bottom: 0;
}

.k_regi_termsAccordionTitle {
  position: relative;
  padding: 20px;
  display: flex;
}

/* .k_regi_termsAccordionTitle::before {
	position: absolute;
	right: 15px;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	top: 28px;
	content: "";
	border-bottom: 5px solid #080808;
}

.collapsed.k_regi_termsAccordionTitle::before {
	transform: rotate(-180deg)
} */

.k_regi_terms_row .k_regi_terms_body {
  padding: 20px;
  position: relative;
}

.k_regi_terms_body:after,
.k_regi_terms_body::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #f1f1f1;
  content: '';
}

.k_regi_terms_body:after {
  background-color: #355fd3;
  z-index: 1;
  left: 20px;
  width: 40px;
}

.k_regi_terms_row.panel {
  border: 1px solid #f1f1f1;
  margin-bottom: 15px;
  padding: 0;
  margin-top: 0;
  box-shadow: none;
  position: relative;
}

.k_regi_terms_row::before {
  margin-right: 4px !important;
}

.k_regi_terms_row:last-child {
  margin-bottom: 0px;
}

.k_regi_terms_row::before {
  visibility: hidden;
  font-size: 0;
  position: absolute;
}

.k_regi_termsAccordionTitle .title,
.k_regi_termsAccordionTitle span {
  font-size: 16px;
  font-weight: 700;
  position: relative;
}

.k_regi_termsAccordionTitle .title {
  flex: 1;
}

.k_regi_termsAccordionTitle span::after {
  content: '';
  height: 13px;
  display: inline-block;
  margin: 0 8px;
  background-color: #355fd3;
  width: 1px;
}

.userScore {
  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 5rem;
    height: 5rem;
    background-color: $primary;
    border-radius: 100%;
    // z-index: -1;
  }
}

.userScore::after {
  top: -2rem;
  left: -2rem;
}

.userScore::before {
  bottom: -2rem;
  right: -2rem;
  opacity: 0.2;
}

// leaderboard
.leaderBoard {
  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 16rem;
    height: 8rem;
    background-color: $black;
    rotate: 45deg;
    opacity: 0.2;
  }
}

.leaderBoard::after {
  top: -4rem;
  right: -6rem;
}

.leaderBoard::before {
  bottom: -4rem;
  left: -6rem;
}
.leaderBoard-card {
  &__body {
    @include rfs(0.8rem, border-radius);
    @include rfs(1rem, padding);
    border: 1px solid transparent;
    &:hover {
      border-color: var(--bs-primary);
    }
  }
  &__bodyList {
    &:hover {
      border-color: transparent;
    }
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    .leaderBoard-card__body {
      border-bottom: 1px solid var(--bs-primary);
      border-top: 0;
      border-left: 0;
      border-right: 0;
      background-color: rgba(var(--bs-primary-rgb), 0.05);
    }
  }
}

.k_regi_terms_ol_2 {
  padding-left: 0 !important;
  margin-top: 0 !important;
}

.rotate-90 {
  transform: rotate(90deg);
}

// .rounded-top-0 {
//   border-top-left-radius: 0 !important;
//   border-top-right-radius: 0 !important;
// }
