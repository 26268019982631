@import '../../../assets/sass/custom';

.medwikiLandingV2 {
  .btn-primary {
    svg {
      color: $white;
      fill: $white;
    }

    &:hover {
      svg {
        color: var(--bs-primary);
        fill: var(--bs-primary);
      }
    }
  }

  .btn-secondary {
    svg {
      color: $white;
      fill: $white;
    }

    &:hover {
      svg {
        color: var(--bs-secondary);
        fill: var(--bs-secondary);
      }
    }
  }

  .swiper {
    cursor: grab;
  }

  .medwikiLandingTop {
    .medwikiTopLeft {
      width: calc(100% - 300px);

      .medwikiFeaturedSlide {
        .medwikiFeaturedPic {
          width: calc(45% + 6rem);
          max-height: 35rem;
          min-height: 35rem;

          .medwikiFeaturedPicIcon {
            height: 9rem;
            left: -4.5rem;
            transform: skew(-45deg);
            width: 9rem;
            z-index: 9;

            svg {
              transform: skew(45deg);
            }

            i {
              transform: skew(45deg);
            }
          }
        }

        .medwikiFeaturedRight {
          width: calc(55% - 6rem);
        }

        .medwikiFeaturedContent {
          width: calc(100% + 17rem);
          margin-left: -17rem;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            width: 0.4rem;
            height: calc(100% - 5rem);
            margin: auto 0;
            background-color: var(--bs-primary);
            border-radius: $border-radius;
          }
        }

        .medwikiFeaturedSponser {
          width: 18rem;

          .swiper-slide,
          .medwikiFeaturedSponserIn {
            height: 4.2rem;
          }
        }

        .swiper-pagination {
          // bottom: 9.35rem;
          bottom: 2.35rem;
          text-align: right;
          padding: 0 2.5rem;

          .swiper-pagination-bullet {
            display: inline-block;
            width: 1.2rem;
            position: relative;
            height: 1.2rem;
            border-radius: 0;
            background-color: transparent;
            opacity: 1;
            vertical-align: middle;

            &:before {
              position: absolute;
              width: 100%;
              height: 100%;
              border-radius: $border-radius-circle;
              background-color: transparent;
              border: 2px solid $light;
              opacity: 1;
              content: '';
              left: 0;
              @include translateY;
            }

            &.swiper-pagination-bullet-active {
              &:before {
                opacity: 1;
                border-color: var(--bs-primary);
                background-color: var(--bs-primary);
              }
            }
          }
        }
      }
    }

    .medwikiLandingRight {
      width: 300px;

      .medwikiLandinSpecialitiesList {
        list-style-type: none;

        li {
          @include cmnTransition;

          .medwikiLandinSpecialitiesListIcon {
            top: 0.15rem;
          }

          &:last-child {
            margin-bottom: 0 !important;
          }

          &:hover {
            color: var(--bs-primary);
            padding-left: 2.8rem;

            .medwikiLandinSpecialitiesListIcon {
              left: 0.8rem !important;
              fill: var(--bs-primary);
            }
          }
        }
      }
    }
  }

  .popularMedwiki {
    position: relative;
    overflow: hidden;

    .headingPart {
      background-color: #eaf7f5;
      min-width: 15rem;
      // line-height: 1.3;
      // padding: 0 1rem;
    }

    .popularMedwikiSlider {
      width: calc(100% - 15rem);

      .popularMedwikiSliderIn {
        padding-right: 8rem;
      }

      .popularMedwikiSliderBox {
        .popularMedwikiPic {
          width: 8rem;
          height: 6rem;
        }

        .popularMedwikiDetails {
          flex: 1;
        }
      }
    }

    .popularMedwikiBtn {
      width: 15rem;
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 38%,
        rgba(255, 255, 255, 1) 100%
      );
      z-index: 9;
    }

    &.topRatedMedwiki {
      background-color: var(--bs-primary);

      .headingPart {
        min-width: 14rem;
      }

      .headingPart {
        background-color: $white;
      }

      .popularMedwikiSlider {
        width: calc(100% - 14rem);

        .popularMedwikiSliderBox {
          &::before {
            background-color: $white;
          }
        }
      }

      .popularMedwikiBtn {
        background-image: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          var(--bs-primary) 38%,
          var(--bs-primary) 100%
        );

        a {
          color: $white;
        }
      }
    }

    &.mostCommentedMedwiki {
      background-color: var(--bs-secondary);

      .headingPart {
        background-color: $white;
        min-width: 17rem;
      }

      .popularMedwikiSlider {
        width: calc(100% - 17rem);

        .popularMedwikiSliderBox {
          &::before {
            background-color: $white;
          }
        }
      }

      .popularMedwikiBtn {
        background-image: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          var(--bs-secondary) 38%,
          var(--bs-secondary) 100%
        );

        a {
          color: $white;
        }
      }
    }
  }
}

@media screen and (max-width: 1599px) {
  .medwikiLandingV2 .medwikiLandingTop .medwikiTopLeft {
    width: calc(100% - 300px);

    .medwikiFeaturedSlide {
      .swiper-pagination {
        padding: 0 2rem;
      }
    }
  }

  .medwikiLandingV2 .medwikiLandingTop .medwikiLandingRight {
    width: 300px;
  }
}

@media screen and (max-width: 1359px) {
  .medwikiLandingV2 .medwikiLandingTop .medwikiTopLeft {
    width: calc(100% - 300px);

    .medwikiFeaturedSlide {
      .medwikiFeaturedPic {
        width: calc(50% - 4rem);
      }

      .medwikiFeaturedRight {
        width: calc(50% + 4rem);
      }

      .medwikiFeaturedContent {
        margin-left: -15rem;
        width: calc(100% + 15rem);
      }

      .swiper-pagination {
        padding: 0 2rem;
        bottom: 0.8rem;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .medwikiLandingV2 .medwikiLandingTop .medwikiTopLeft .medwikiFeaturedSlide {
    .medwikiFeaturedPic {
      width: calc(50% - 2rem);
      max-height: 30rem;
      min-height: 30rem;
    }

    .medwikiFeaturedRight {
      width: calc(50% + 2rem);
    }

    .medwikiFeaturedContent {
      margin-left: -13rem;
      width: calc(100% + 13rem);
    }

    .swiper-pagination {
      padding: 0 2rem;
      // bottom: 4.35rem;
    }
  }

  // .medwikiFeaturedContent {
  //   padding: 2rem;
  // }
}

@media screen and (max-width: 991px) {
  .medwikiLandingV2 .medwikiLandingTop {
    .medwikiTopLeft {
      width: 100%;

      .medwikiFeaturedSlide {
        .swiper-pagination {
          padding: 0 2rem;
          bottom: 2.35rem;
        }
      }
    }

    .medwikiLandingRight {
      width: 100%;
      margin-top: 1.8rem;
    }
  }
}

@media screen and (max-width: 849px) {
  .medwikiLandingV2 .medwikiLandingTop {
    .medwikiTopLeft {
      width: 100%;

      .medwikiFeaturedSlide {
        .swiper-pagination {
          padding: 0 2rem;
          bottom: 0.8rem;
        }
      }
    }

    .medwikiLandingRight {
      width: 100%;
      margin-top: 1.8rem;
    }
  }
}

@media screen and (max-width: 767px) {
  .medwikiLandingV2 {
    .medwikiLandingTop .medwikiTopLeft .medwikiFeaturedSlide {
      .swiper-pagination {
        bottom: 1.2rem;
        text-align: center;
      }

      .medwikiFeaturedMain {
        height: 100% !important;
      }

      .medwikiFeaturedPic {
        width: 100%;
        min-height: 0;
        height: 25rem;
      }

      .medwikiFeaturedRight {
        width: 100%;

        .medwikiFeaturedContent {
          width: 100%;
          box-shadow: none !important;
          margin: 0;

          &::before {
            display: none;
          }
        }
      }

      .medwikiFeaturedSponser {
        width: 15rem;

        .swiper-slide,
        .medwikiFeaturedSponserIn {
          height: 3.5rem;
        }
      }
    }

    .popularMedwiki {
      flex-direction: column;

      .headingPart {
        background-color: transparent;
        justify-content: space-between !important;
        min-width: 100%;

        span {
          font-size: $h3-font-size;

          span {
            display: inline-block !important;
            padding-left: 0.7rem;
            font-size: $h3-font-size;
          }
        }
      }

      .popularMedwikiSlider {
        width: 100% !important;

        .popularMedwikiSliderIn {
          padding-right: 3rem;
        }

        .popularMedwikiSliderBox {
          .popularMedwikiPic {
            width: 9rem;
            height: 7rem;
          }
        }
      }

      .popularMedwikiBtn {
        display: none !important;
      }

      &.topRatedMedwiki,
      &.mostCommentedMedwiki {
        .headingPart {
          background-color: transparent;

          span {
            color: $white !important;
          }
        }
      }
    }
  }
}
