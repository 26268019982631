// ==================================colors
// @import '../../../../node_modules/bootstrap/scss/functions';
// @import "./buttons";

// // ==================================colors theme
/* purgecss start ignore */
@mixin colorTheme(
  $clr_primary: #d74040,
  $clr_secondary: #07529b,
  $clr_bodyBg: #ffffff,
  $clr_light: #eaeaea
) {
  --bs-link-color: #{$clr_primary};
  --bs-link-color-rgb: #{to-rgb($clr_primary)};
  --bs-link-hover-color: #{$clr_primary};
  --bs-link-hover-color-rgb: #{to-rgb($clr_primary)};

  --bs-primary: #{$clr_primary};
  --bs-primary-text: #{$clr_primary};
  --bs-primary-rgb: #{to-rgb($clr_primary)};

  --bs-secondary: #{$clr_secondary};
  --bs-secondary-text: #{$clr_secondary};
  --bs-secondary-rgb: #{to-rgb($clr_secondary)};

  --bs-light-rgb: #{to-rgb($clr_light)};
  --bs-light: #{$clr_light};
  --bs-body-bg: #{$clr_bodyBg};
  // --bs-soft-primary: rgba(#{to-rgb($clr_primary), 0.15}) !important; --option

  --bs-soft-primary: color-mix(in srgb, var(--bs-primary) 15%, white) !important;

  --bs-soft-secondary: color-mix(in srgb, var(--bs-secondary) 15%, white) !important;

  // ios
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      --bs-soft-primary: rgba(var(--bs-primary-rgb), 0.15) !important;
      --bs-soft-secondary: rgba(var(--bs-secondary-rgb), 0.15) !important;
    }
  }
  // soft primary========
  .bg-soft-primary {
    background-color: var(--bs-soft-primary) !important;
    color: var(--bs-primary);

    &.cursorPointer:hover {
      background-color: var(--bs-primary) !important;
      color: $white;
    }
  }

  .border-soft-primary {
    border-color: var(--bs-soft-primary) !important;
  }

  .text-soft-primary {
    color: var(--bs-soft-primary);
  }

  // soft secondary========
  .bg-soft-secondary {
    background-color: var(--bs-soft-secondary) !important;
    color: var(--bs-secondary);

    &.cursorPointer:hover {
      background-color: var(--bs-secondary) !important;
      color: $white;
    }
  }

  .border-soft-secondary {
    border-color: var(--bs-soft-secondary) !important;
  }

  .text-soft-secondary {
    color: var(--bs-soft-secondary);
  }
}
[data-bs-theme='All'] {
  @include colorTheme();
}

@each $key, $val in $theme-colors {
  @if $key != 'primary' and $key != 'secondary' {
    :root {
      --bs-soft-#{$key}: color-mix(in srgb, var(--bs-#{$key}) 15%, white) !important;
    }
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        :root {
          --bs-soft-#{$key}: rgba(var(--bs-#{$key}-rgb), 0.15) !important;
        }
      }
    }

    .bg-soft-#{$key} {
      background-color: var(--bs-soft-#{$key}) !important;

      @if $key == 'light' or $key == 'white' {
        color: $black !important;
      } @else {
        color: $val;
      }

      &.cursorPointer:hover {
        background-color: $val !important;

        @if $key == 'light' or $key == 'white' {
          color: $black !important;
        } @else {
          color: $white !important;
        }
      }
    }

    .border-soft-#{$key} {
      border-color: var(--bs-soft-#{$key}) !important;
    }

    .text-soft-#{$key} {
      color: var(--bs-soft-#{$key}) !important;
    }
  }
}

// fill stroke

@each $key, $val in $theme-colors {
  .fill-#{$key} {
    fill: var(--bs-#{$key}) !important;
  }
  .stroke-#{$key} {
    stroke: var(--bs-#{$key}) !important;
  }
  @include color-mode(dark) {
    @if $key == 'dark' or $key == 'black' {
      .fill-#{$key} {
        fill: $white !important;
      }
      .stroke-#{$key} {
        stroke: $white !important;
      }
    }
    // @else if $key == "white" or $key == "white" {
    //   .fill-#{$key} {
    //     fill: $white !important;
    //   }
    //   .stroke-#{$key} {
    //     stroke: $white !important;
    //   }
    // }
  }
}
/* purgecss start ignore */
