@import '../../../assets/sass/custom';

/*=============================----------- Header*/
.mblHeader {
  z-index: 1031;

  .mblNav {
    padding: 0 2%;
    border-radius: 0 0 $border-radius $border-radius;
    height: 6rem;

    .mblNavLeft {
      width: 5rem;
      height: 5rem;

      img {
        max-height: calc(100% - 0.8rem);
        max-width: calc(100% - 0.8rem);
      }
    }

    .mblNavLeftTtl {
      line-height: normal !important;
    }

    .mblNavRight {
      .mblNavRight_link {
        width: 4.2rem;
        height: 4.2rem;

        &.mblTakeTourBtn {
          transform: translateZ(0);

          &:hover {
            background-color: rgba(255, 255, 255, 0.42) !important;
          }
        }

        &.mblNotificationBtn {
          .notificationCount {
            width: 2.5rem;
            height: 2.5rem;
            top: -1rem;
            right: -0.5rem;
          }
        }
      }

      .deskNavDots {
        .dropdown-toggle {
          @include displayProp($justifyContent: center);
          // color: $white;
          color: $black;
          width: 4.2rem;
          height: 4.2rem;
          border-radius: $border-radius;
          @include rippleEffect;

          &:after {
            display: none;
          }

          &:hover,
          &:focus {
            background-color: transparent;
          }
        }

        .dropdown-menu {
          position: absolute;
          right: 0;
          left: auto;
          width: 18rem;
          top: calc(100% + 2rem);
          margin: 0;
          box-shadow: $box-shadow;
          border: none;
          border-radius: $border-radius;
        }

        .dropdown-item {
          // padding-left: 4rem !important;

          svg {
            left: 1rem;
          }

          &.deskNavVault {
            span {
              height: 2.4rem;
              min-width: 2.4rem;
            }
          }

          &:hover {
            background-color: #e3e8eb;
          }
        }
      }
    }
  }

  .mblMarquee {
    height: 2.6rem;

    .overlay {
      display: none;
    }
  }
}
