@import '../../../assets/sass/custom';

.medwikiRelatedBox {
  .medwikiRelatedBoxIn {
    .medwikiRelatedPost {
      &:last-child {
        margin-bottom: 0 !important;
      }

      .medwikiRelatedPostPic {
        width: 8rem;
        height: 8rem;

        .overlay {
          background-color: rgba(0, 0, 0, 0.058);
        }

        .medwikiRelatedPostIcon {
          top: 1rem;
          left: -1.5rem;
          width: 5rem;
        }
      }

      .medwikiRelatedPostDetails {
        flex: 1;

        li {
          position: relative;
          padding: 0 0.8rem;
          margin: 0;

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            height: calc(100% - 0.5rem);
            width: 1px;
            background-color: $dark;
          }

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;

            &::before {
              display: none;
            }
          }
        }
      }

      &.clinicalVideo {
        .medwikiRelatedPostPic {
          .medwikiRelatedPostIcon {
            background-color: var(--bs-secondary);
          }
        }
      }

      &.liveCME {
        .medwikiRelatedPostPic {
          .medwikiRelatedPostIcon {
            background-color: var(--bs-primary);
          }
        }
      }

      &.medwiki {
        .medwikiRelatedPostPic {
          .medwikiRelatedPostIcon {
            background-color: var(--bs-secondary);
          }
        }
      }

      &.grTraining {
        .medwikiRelatedPostPic {
          .medwikiRelatedPostIcon {
            background-color: $purple;
          }
        }
      }

      &.spqBox {
        background-color: #edf8f6;

        .medwikiRelatedPostPic {
          width: 6.5rem;
          height: 6.5rem;

          .overlay {
            display: none;
          }

          .medwikiRelatedPostIcon {
            background-color: var(--bs-primary);
          }
        }
      }
    }
  }

  .cmnBtn {
    &:hover {
      background-color: var(--bs-secondary);
      color: $white;
    }
  }
}
// .medWikiDetailsV2 {

// }

.medWikiDetailsV2Main {
  .detailsPageLeft {
    // width: calc(100% - 300px);

    .ratingStar {
      // input {
      //   &:checked {
      //     & ~ label {

      //     }
      //   }
      // }
    }

    .dtlsPageLeftBody {
      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 35rem;
        height: 35rem;
        background-color: #f8f8f8;
        border-radius: $border-radius-circle;
      }

      &::before {
        top: -10rem;
        right: -10rem;
      }

      &::after {
        bottom: -10rem;
        left: -10rem;
      }

      .dtlsTopPart {
        .detailsPageLeftPic {
          width: calc(40% + 4rem);
          max-height: 30rem;
        }

        .detailsPageLeftVid {
          .detailsPageLeftPicIn {
            max-height: 32rem;
          }

          .dtlsTrigrBar {
            min-width: 27rem;
          }
        }

        .dtlsTtlArea {
          width: calc(60% - 4rem);

          .dtlsSpeclty {
            li {
              &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 0.1rem;
                background-color: $black;
              }

              &:first-child {
                padding-left: 0 !important;
              }

              &:last-child {
                padding-right: 0 !important;

                &::before {
                  display: none;
                }
              }
            }
          }

          .dtlsTrigrBar {
            ul {
              list-style-type: none;

              li {
                @include cmnTransition;

                .icon {
                  width: 2.5rem;
                  height: 2.5rem;

                  svg {
                    width: 2.5rem;
                    height: 2.5rem;
                    @include cmnTransition;

                    &.fillIcon {
                      opacity: 0;
                    }
                  }
                }

                &.active {
                  .icon svg {
                    &.fillIcon {
                      opacity: 1;
                    }

                    &.lineIcon {
                      opacity: 0;
                    }
                  }
                }

                &:hover {
                  color: var(--bs-primary);
                }
              }
            }
          }
        }

        .dtlsTtlAreaVid {
          .dtlsTrigrBar {
            min-width: 27rem;

            ul {
              li {
                &:last-child {
                  margin-right: 0rem;
                }
              }
            }
          }
        }
        @include media-breakpoint-down(md) {
          .dtlsTtlArea.w-50,
          .detailsPageLeftPic.w-50 {
            width: 100% !important;
          }
        }
      }

      .dtlsBottomPart {
        .accordion-button {
          background-color: transparent !important;
          @media screen and (max-width: 767px) {
            padding-left: 0 !important;
            padding-right: 0 !important;
          }
        }
        .imgPart {
          margin-bottom: 4rem;
        }

        .textPart {
          img {
            max-width: 100% !important;
            height: auto !important;
          }

          ul {
            list-style-position: outside;
            margin-bottom: 1.5rem;
            margin-left: 0.9rem;
            padding-left: 1.1rem;

            li {
              margin-bottom: 1.2rem;
            }
          }

          table {
            margin: 1rem 0 2rem 0 !important;
            overflow: auto;
            display: block;
            width: 100% !important;
            border: none;

            td,
            th {
              border: 1px solid #dee2e6 !important;
              padding: 1.2rem !important;
            }
          }

          & *:not(sup *, sup, h1, h2, h3, h4, h5, h6) {
            font-size: $font-size-base * 1.5 !important;
            line-height: 1.6 !important;
          }
        }

        .imgPart {
          .detailsInnerPic {
            height: auto;
            max-width: calc(60% - 6rem);
            padding: 2.5rem 2.5rem 2.5rem 0;
            position: relative;
            @include cmnTransition($time: 0.25s);

            .detailsInnerPicGraphRight {
              border-radius: 1rem;
              bottom: 0;
              content: '';
              height: 100%;
              position: absolute;
              right: 0;
              width: calc(35% - 2.5rem);
            }

            .detailsInnerPicGraphLeft {
              background-color: rgb(253, 206, 185);
              border-radius: 1rem;
              bottom: 0;
              content: '';
              height: 100%;
              position: absolute;
              left: 2.5rem;
              width: calc(65% - 2.5rem);
            }

            .detailsInnerPicIn {
              z-index: 1;
              max-height: 35rem;

              .onHoverIcon {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                opacity: 0;

                .overlay {
                  background: #000;
                  opacity: 0.35;
                }

                .zoomIcon {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  background: rgba(255, 255, 255, 0.75);
                  padding: 1rem;
                  z-index: 9;
                  border-radius: $border-radius-xl;
                }
              }
            }

            &:hover {
              .detailsInnerPicIn {
                .onHoverIcon {
                  opacity: 1;
                }
              }
            }
          }
        }

        .citationPart {
          .accordion-item {
            border: 6px solid #eaeaea;
            background-color: $white;
          }

          .accordion-button {
            justify-content: space-between;
            font-size: $h3-font-size;
            font-weight: 600;
            padding: 1.5rem 0;
            background-color: $white;

            &::after {
              display: none;
            }

            &:not(.collapsed) {
              background-color: transparent;
              color: $black;
              box-shadow: none;
            }

            &:focus {
              border-color: none;
              box-shadow: none;
            }

            &[aria-expanded='true'] {
              position: relative;
              padding-bottom: 2rem;
              margin-bottom: 2rem;

              &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #e6e6e6;
                display: block;
              }

              &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 10rem;
                height: 1px;
                background-color: var(--bs-primary);
                z-index: 9;
              }

              svg.arrowDown {
                transform: rotate(180deg);
              }
            }
          }

          .accordion-body {
            li {
              margin-bottom: 2rem;

              &:last-child {
                margin-bottom: 0;
              }

              a {
                color: var(--bs-primary);
                word-break: break-all;
              }
            }

            cite {
              font-style: normal;
            }
          }
        }
      }
    }

    .medwikiReview {
      .medwikiReviewArea {
        .medwikiReviewAreaTitle {
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #e6e6e6;
          }

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 10rem;
            height: 1px;
            background-color: var(--bs-primary);
            z-index: 9;
          }
        }

        .medwikiReviewAreaBody {
          .medwikiRatingBox {
            background-color: #f1f2f3;
            border: 1px solid #dde2fa;
          }
        }
      }
    }
  }

  .detailsPageRight {
    // width: 300px;
    // display: flex;
    // flex-direction: column;

    .medwikiSponsorBox {
      .medwikiDetailsSponser {
        // width: 18rem;
        .swiper-slide,
        .medwikiDetailsSponserIn {
          height: 4.2rem;
        }

        .swiper-slide.medwikiDetailsSponserIn {
          opacity: 0 !important;

          &.swiper-slide-active {
            opacity: 1 !important;
          }
        }
      }
    }

    .medwikiChannelBox {
      background-color: #eaf2f4;
      border: 1px solid #bdd8e0;

      .medwikiChannelBoxIn {
        .medwikiChannelPart {
          width: calc(100% - 9rem);

          .medwikiChannelPic {
            height: 6rem;
            width: 6rem;
          }

          .medwikiChannelName {
            flex: 1;
          }
        }

        .followBtn {
          width: 9rem;

          &::after {
            content: '';
            position: absolute;
            top: 1px;
            left: -1.2rem;
            height: 100%;
            width: 50%;
            transform: skewX(-15deg);
            background-color: var(--bs-secondary);
          }
        }
      }
    }
  }
}

.footnote a {
  color: var(--bs-secondary) !important;
}

@media screen and (max-width: 991px) {
  .medWikiDetailsV2 .medWikiDetailsV2Main {
    .detailsPageLeft {
      width: 100%;
    }

    .detailsPageRight {
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .medWikiDetailsV2 {
    .medWikiDetailsV2Main .detailsPageLeft {
      .dtlsPageLeftBody {
        &::before,
        &::after {
          content: '';
          width: 25rem;
          height: 25rem;
        }

        &::before {
          top: -8rem;
          right: -8rem;
        }

        &::after {
          bottom: -8rem;
          left: -8rem;
        }

        .dtlsTopPart {
          .detailsPageLeftPic {
            width: 100%;

            &::before {
              left: -0.8rem;
            }

            .detailsPageLeftPicIn {
              max-height: 32rem;
            }
          }

          .dtlsTtlArea {
            width: 100%;

            .dtlsSpeclty {
              font-size: $h5-font-size;

              li {
                &::before {
                  content: '';
                  position: absolute;
                  top: 0;
                  right: 0;
                  height: 100%;
                  width: 0.1rem;
                  background-color: $black;
                }

                &:first-child {
                  padding-left: 0;
                }

                &:last-child {
                  padding-right: 0;

                  &::before {
                    display: none;
                  }
                }
              }
            }

            h1 {
              font-size: $h3-font-size;
            }

            .dtlsTrigrBar {
              ul {
                list-style-type: none;

                li {
                  @include cmnTransition;

                  .icon {
                    width: 2.5rem;
                    height: 2.5rem;

                    svg {
                      width: 2.5rem;
                      height: 2.5rem;
                      position: absolute;
                      left: 0;
                      @include translateY;
                      @include cmnTransition;

                      &.fillIcon {
                        opacity: 0;
                      }
                    }
                  }

                  &.active {
                    .icon svg {
                      &.fillIcon {
                        opacity: 1;
                      }

                      &.lineIcon {
                        opacity: 0;
                      }
                    }
                  }

                  &:hover {
                    color: var(--bs-primary);
                  }
                }
              }
            }
          }
        }

        .dtlsBottomPart {
          .imgPart {
            margin-bottom: 3rem;
          }

          .textPart {
            font-size: $h4-font-size;

            ul {
              font-size: $h4-font-size;
            }
          }

          .imgPart {
            .detailsInnerPic {
              max-width: 100%;
              padding: 1.5rem 1.5rem 1.5rem 0;

              &::before {
                width: calc(35% - 2rem);
              }

              &::after {
                width: calc(65% - 2rem);
              }
            }
          }

          .citationPart {
            .accordion-item {
              border: 4px solid #eaeaea;
            }

            .accordion-button {
              &[aria-expanded='true'] {
                position: relative;
                padding-bottom: 2rem;
                margin-bottom: 2rem;

                &::before {
                  content: '';
                  width: 6rem;
                }
              }
            }

            .accordion-body {
              font-size: $h4-font-size;
              padding-bottom: 2rem;

              li {
                margin-bottom: 2rem;
              }
            }
          }
        }
      }

      .medwikiReview {
        .medwikiReviewArea {
          .medwikiReviewAreaTitle {
            &::before {
              content: '';
              width: 6rem;
            }
          }
        }
      }
    }
  }
}
