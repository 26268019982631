// @import "../../../assets/sass/custom";

.mblHeaderDetails {
  .mblMarquee {
    height: 2.6rem;

    .overlay {
      display: none;
    }
  }

  .mblHeaderDetailsMain {
    padding: 0 2%;
    height: 7rem;

    .mblDtlsNavLeft img {
      max-width: 70%;
      max-height: 6.4rem;
    }
  }
}