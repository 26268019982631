@import '../../../assets/sass/custom';

.mblFooter {
  .mblFooterUl {
    li {
      a {
        flex: 1;
        height: 7rem;

        span {
          height: 3rem;
          i {
            @include translateXY;
            position: absolute;
            font-size: 2rem;
          }
          .mblmenuSVG {
            width: 3rem;
            height: 3rem;
            fill: $black;
          }
        }
      }

      a.active {
        color: var(--bs-primary) !important;

        .mblmenuSVG,
        svg {
          color: var(--bs-primary) !important;
          fill: var(--bs-primary) !important;
          stroke: var(--bs-primary) !important;
        }
        .mblmenuSVG,
        svg.fill-white {
          color: var(--bs-white) !important;
          fill: var(--bs-white) !important;
          stroke: var(--bs-white) !important;
        }
      }

      .more_mobile {
        flex: none;
        background-color: #f4f4f4;
        width: 7rem;
        height: 7rem;
      }

      .mblFtr_moreMenu {
        width: 2.5rem;
        height: 2.5rem;
        // @include translateXY;
        transform: translateX(-50%) translateY(-50%) translateZ(0);
        @include cmnTransition;

        span {
          @include translateX;
          margin: 0;
          @include cmnTransition;

          &::after {
            content: '';
            top: 0;
            left: -1rem;
          }

          &:before {
            content: '';
            top: 0;
            right: -0.9rem;
          }

          &:nth-child(1) {
            top: 0.2rem;
          }

          &:nth-child(2) {
            @include translateXY;
          }

          &:nth-child(3) {
            bottom: 0.2rem;
          }
        }

        span,
        span::after,
        span:before {
          width: 0.4rem;
          height: 0.4rem;
          background-color: $black;
          border-radius: $border-radius-circle;
          position: absolute;
        }
      }

      .more_mobile.active {
        background-color: var(--bs-primary);

        .mblFtr_moreMenu {
          transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(-180deg) !important;
        }

        .mblFtr_more {
          &:nth-child(2),
          &:before,
          &::after {
            display: none;
          }

          &:nth-child(1),
          &:nth-child(3) {
            width: 2px;
            height: 2.5rem;
            border-radius: 0;
            background-color: $white;
            top: 50%;
          }

          &:nth-child(1) {
            transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(45deg) !important;
          }

          &:nth-child(3) {
            transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(-45deg) !important;
          }
        }
      }
    }
  }

  .ftrBtmSubmenu {
    background-color: #fafafa;
    height: calc(100dvh - 15.6rem);
    bottom: 6rem;
    transform: translateY(100%);
    @include cmnTransition;

    &.moreMenuShowUL {
      transform: translateY(0);
      opacity: 1;
    }

    .drawerMainMenu {
      ul {
        li {
          a {
            word-break: normal;
            span {
              height: 3rem;

              .mblmenuSVG {
                width: 5.2rem;
                height: 3rem;
                filter: brightness(0);
              }
            }

            &.active {
              color: var(--bs-primary) !important;

              .mblmenuSVG {
                color: var(--bs-primary);
                fill: var(--bs-primary);
                filter: brightness(1);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .mblFooter {
    .mblFooterUl li a {
      font-size: 0.95rem;
      height: 6rem;
      padding: 0.7rem 0.15rem;
    }

    .ftrBtmSubmenu .drawerMainMenu ul li a,
    .mblFooterUl li a {
      text-align: center;
      span {
        margin-bottom: 0.7rem;
        height: 2.6rem;

        .mblmenuSVG {
          height: 2.8rem;
        }
      }

      span.diagnosisMenu {
        .mblmenuSVG {
          width: 6rem;
          height: 4rem;
        }
      }
    }

    // .mblFooterUl li a.diagnosisMenu {
    //   span {
    //     .mblmenuSVG {
    //       width: 6rem;
    //       height: 4rem;
    //     }
    //   }
    // }
    .ftrBtmSubmenu .drawerMainMenu ul li a {
      font-size: $h5-font-size;
    }
  }
}

@media screen and (max-width: 359px) {
  .mblFooter {
    .ftrBtmSubmenu .drawerMainMenu ul li a {
      font-size: $font-size-base;
    }
  }
}
