@import '../../../../assets/sass/custom';

.cardV1Box {
  .graph-1 {
    width: 2px;
    height: 2.5rem;
  }

  .graph-2 {
    width: 2.5rem;
    height: 2px;
  }

  .medWikiCrdV1Top {
    // height: 13rem;
    height: 22rem;

    .cardTimeLogo {
      z-index: 5;
    }

    .ssnCardV1Boxtimer {
      span.d-block {
        &::after {
          position: absolute;
          bottom: 0;
          @include translateX;
          content: '';
          background-color: $white;
          height: 1px;
          width: 1.2rem;
          border-radius: $border-radius;
        }
      }
    }

    .grCardV2Certificate {
      min-height: 3.4rem;
      padding-left: 4rem !important;
      position: relative;

      .grCardV2CertificateIcon {
        width: 4rem;
        background-color: $danger;
        clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
      }
    }
  }

  .medWikiCrdV1Btm {
    .cardV2Statistics {
      li {
        &:last-child {
          margin-right: 0 !important;
        }
      }
    }

    .courseProgress {
      left: 1.5rem;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      .circleProgress {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .crdV1topPart {
    .crdV1topPartGraph {
      content: '';
      width: 2px;
    }

    .crdV1Speclty {
      svg {
        margin-left: 0.3rem;
      }

      &:hover {
        color: $black;
      }
    }
  }

  .cardV1Topic {
    .cardV1ProfilePic {
      width: 5.8rem;
      height: 5.8rem;

      img {
        @include imgAutoSize;
      }
    }

    .cardV1TopicContent {
      a:hover {
        color: $black;
      }
    }
  }

  .cardV1Ftr {
    .cardV1FtrBtn {
      @include cmnTransition;

      .cardV1BtnBorder {
        width: 100%;
        height: 100%;
        border-width: 1px;
        border-style: solid;
        @include cmnTransition;
      }

      .cardV1BtnHover {
        width: calc(100% + 2rem);
        height: calc(100% + 2rem);
        opacity: 0;
        @include cmnTransition;
      }

      span {
        @include cmnTransition;
        color: $black;
      }

      svg {
        @include cmnTransition;
        color: $black;
        margin-left: 0.2rem;
      }

      &:hover {
        color: $white;

        .cardV1BtnHover {
          opacity: 1;
        }

        span,
        svg {
          color: $white;
        }
      }
    }

    .crdV1sponser {
      width: 40%;

      .swiper-slide,
      .crdV1sponserIn {
        height: 4rem;

        img {
          @include imgAutoSize;
        }
      }
    }
  }
}

.withChanel {
  .ListingCardBox .cardV1TopicContent {
    width: calc(100% - 5.8rem);
    padding-left: 1rem;
  }
}

@media screen and (max-width: 1199px) {
  .ListingCardBox {
    .ListingCardBoxPic {
      height: 11rem;
    }

    .cardV1Ftr {
      padding-left: 1rem;
    }
  }
}

.clVideoCardV1BoxPic {
  .clVideoCardV1Time {
    left: 1rem;
    bottom: 1rem;
  }

  .clVideoCardV1Keep {
    right: 1rem;
    bottom: 1rem;
  }

  .crdV1sponser {
    right: 1.2rem;
    top: 1.2rem;
    width: 16rem;
    padding: 0.4rem 1rem;
    z-index: 4;

    .swiper-slide,
    .crdV1sponserIn {
      height: 4.4rem;
    }
  }
}

@media screen and (max-width: 991px) {
  .mblscreen {
    .crdV1DoctorBoxIn .crdV1DoctorPic {
      height: 4.4rem;
      width: 4.4rem;
    }
  }
}


.crdV1sponser {
  width: 30%;
  .swiper-slide,
  .crdV1sponserIn {
    height: 4rem;
    img {
      @include imgAutoSize;
    }
  }
}