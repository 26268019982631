@import '../../../assets/sass/custom';

.cmnDocArea {
  height: 52px;

  .cmnDocShowArea {
    overflow: hidden;
    height: 52px;

    &::first-line {
      background-color: royalblue;
    }
  }

  .cmnDoc {
    width: 52px;
    height: 52px;

    .overlay {
      display: none;
    }

    &:first-child {
      margin-left: 0 !important;
    }

    &.lastCircle {
      .overlay {
        display: flex;
      }
    }
  }
}