@import '../custom';
$icon-size: (
  '16': 1.6,
  '18': 1.8,
  '20': 2,
  '24': 2.4,
  '28': 2.8,
  '32': 3.2,
  '36': 3.6,
  '48': 4.8,
  '50': 5,
  '60': 6,
  '80': 8,
  '96': 9.6,
  '128': 12.8
);

@each $key, $val in $icon-size {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      .icon-size#{$infix}-#{$key} {
        width: $val + rem !important;
        height: $val + rem !important;
      }
    }
  }

  .h-#{$key}px {
    height: $val + rem !important;
  }
  .w-#{$key}px {
    width: $val + rem !important;
  }
}
