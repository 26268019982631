body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* .iosSpecial .mblFooterIosSafeArea{background-color: #1e51b0; top: 0; position: fixed; z-index: 5; left: 0; width: 100%;padding-top: env(safe-area-inset-top, 20px) !important;}
.iosSpecial .sharePageBody .desktopBodyIn{ background-color: #fafafa;}
.iosSpecial .mblscreen .mblHeaderDetails,.mblscreen .mblHeader,.iosSpecial .mblscreen .shareHeader{ padding-top: env(safe-area-inset-top, 20px) !important;  background-color: #1e51b0;}
.iosSpecial .mblscreen .navbar-brand{padding-top: env(safe-area-inset-top, 20px) !important;}
.iosSpecial .mblFooter .mblFooterUl{ padding-bottom: env(safe-area-inset-bottom, 0.5vh) !important; }
.iosSpecial .mblbodyIos{padding-top: env(safe-area-inset-top, 20px) !important; padding-bottom: env(safe-area-inset-bottom, 0.5vh) !important;}  */

.iosSpecial-safe-area-top{height: 5.1vh !important;}
.iosSpecial-safe-area-bottom{height: 1.5vh !important;}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
