@import '../../../assets/sass/custom';

.cmnLandingSlideRow {
  &:last-child {
    margin-bottom: 0 !important;
  }
  .cmnLndngRowSlidePrrnt {
    margin: 0 0 0 -2rem;
  }
  .cmnLndngRowSlide {
    padding: 1.5rem 8rem 2rem 2rem;
  }
}

@media screen and (max-width: 767px) {
  .cmnLndngRowSlide {
    padding-right: 2.5rem;

    .cmnLandingSlideRow {
      margin-bottom: 1.8rem;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}
