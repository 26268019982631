// @import "./variables/index";
$white: #ffffff;
$black: #161616;
$green: #2e987b;
$yellow: #e8c11f;
$red: #d82650;
$orange: #f15a29;
$gray-300: #e5e5e5;
$gray-600: #525252;
$extra-light: #f8f9fa;
$dark: $gray-600;
$purple: #6715cd;
$primary: #d74040;
$secondary: #07529b;
$light: $gray-300;
$body-color: $gray-600;
// $body-bg: #f0f0f0;
$body-bg: #ffffff;
$gray-extra-light: $extra-light;
$premiumBgColor: linear-gradient(45deg, #cf7000 10%, #ffdd23 52%, #db7600 90%);
$premiumColor: #f19316;

// box-shadow
$box-shadow: 0.2rem 0.2rem 1rem 0 rgba($black, 0.12);
$box-shadow-sm: 8px 8px 10px -5px rgba(0, 0, 0, 0.15);

// $font-size-root: 62.5% !important;

$font-size-base: 1rem;
$link-decoration: none;
$enable-negative-margins: true;
$font-family-sans-serif: 'Poppins', sans-serif;

$table-bg: transparent;

$state: true;
//========================heading h1 h2 h3 h4 h5 h6
$headings-margin-bottom: 0;

$border-radius: 0.6rem;
$border-radius-sm: 0.4rem;
$border-radius-lg: 1rem;
$border-radius-xl: 1.5rem;
$border-radius-circle: 100%;

$h1-font-size: $font-size-base * 2.4;
$h3-font-size: $font-size-base * 1.8;
$h5-font-size: $font-size-base * 1.2;
$h4-font-size: $font-size-base * 1.4;
$tooltip-color: var(--bs-white);
$tooltip-bg: var(--bs-black);
$transition-base: all 0.2s ease-in-out;
