
// Apply the styles to FullCalendar elements

:root {
  --fc-button-text-color: #{rgba($white, 1)};
  --fc-button-bg-color: #{mix($gray-600, $secondary, 1)};
  --fc-button-border-color: #{darken(mix($gray-600, $secondary, 0.5), 5%)};
  --fc-button-hover-bg-color: #{darken(mix($gray-600, $secondary, 0.5), 5%)};
  --fc-button-hover-border-color: #{rgba($white, 0.25)};
  --fc-button-active-bg-color: #{darken(mix($gray-600, $secondary, 0.5), 10%)};
  --fc-button-active-border-color: #{darken(mix($gray-600, $secondary, 0.5), 10%)};
}

.fc-toolbar .fc-button-group .fc-button {
  &:focus,
  &:active,
  &:not(:disabled) {
    box-shadow: none !important;
  }
}

.fullCalendar .fc .fc-button {
  text-transform: capitalize;
}

.fc-col-header-cell-cushion {
  color: #ddd !important;
}

.fullCalendar {
  .fc {
    .fc-toolbar-title {
      @include font-size(1.6rem);
      font-weight: 600;
    }
  }
}


// .fullCalendar .fc .fc-toolbar-title {
//   font-size: 1.3em !important;
//   font-weight: 600 !important;
// }
// .fc-col-header-cell-cushion {
//   color: #ddd !important;
// }

// .fc-toolbar .fc-button-group .fc-button {
//   &:focus,
//   &:active,
//   &:not(:disabled) {
//     box-shadow: none !important;
//   }
// }

// .fullCalendar .fc .fc-button {
//   text-transform: uppercase !important;
// }

// .fullCalendar .fc .fc-toolbar-title {
//   font-size: 1.3em !important;
//   font-weight: 600 !important;
// }
// .fc-col-header-cell-cushion {
//   color: #ddd !important;
// }
